import Service from "../Service";
const resource = "/lotproductionselectionpalletList/";

export default {

   

    saveDiscart(obj, requestID) {
        return Service.post(resource + "saveDiscart", obj, {
            params: { requestID: requestID },
        });
    },

   
};
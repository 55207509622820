<template v-slot:no-data>
	<div>
		<v-container style="display: contents;">
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-card dense >
						<v-row  style="margin: auto;">
							<v-col>
								<s-select-definition
                                    :def="1363"
                                    label="Tipo de parihuela"
                                    v-model="itemHead.TypePallet"
                                    clearable
                                />
							</v-col>
							<v-col>
								<s-select-definition
                                    :def="1440"
                                    label="Tipo de descarte"
                                    v-model="itemHead.PdlTypeDiscart"
                                    clearable
                                >
                                </s-select-definition>
							</v-col>
							<v-col>
								<v-btn
									fab
									x-small
									color="success"
									@click="CreatePallet()">
									<i class="fas fa-plus"></i>
								</v-btn>
							</v-col>
						</v-row>
                        <v-divider ></v-divider>
						<v-row style="margin: auto;">		
							<v-col
								cols="6"
								lg="3"
								md="4"
								:key="col.PdlID"
								v-for="col in itemsPallet"
							>
                                <v-badge color="white">
                                    <template v-slot:badge>							 
                                        <v-btn	
                                            style="margin-left: -29px;margin-top: 2px;"
                                            fab
                                            small
                                            color="white"
                                            @click="removePallet(col)"
                                        >
                                            <v-icon 
                                                color="error" 
                                                class="fas fa-trash"
                                                small>
                                            </v-icon>
                                        </v-btn>								 
                                    </template>
                                    <v-card >								 
                                        <v-card-title style="background: #eaeaea;">
                                            <span class="text-h6 font-weight-light"
                                                >{{ col.TypeDiscartN }}
                                            </span>
                                        </v-card-title>
                                        <v-card-text style="display: grid; background: #eaeaea;"> 
                                            <v-img
                                                lazy-src="../../../assets/pallets44.png"
                                                max-height="200"
                                                max-width="200"
                                                src="../../../assets/pallets44.png"
                                                ><v-btn
                                                    style="border-radius: 100%; height: 44%; width: 30%; margin: auto; display: flex; justify-content: space-evenly; "
                                                    color="warning"
                                                    @click="openModal(col)"
                                                >
                                                    <span class="white--text text-h4">
                                                        {{col.SumDldQuantityJaba}}
                                                    </span>
                                                </v-btn>
                                            </v-img>
                                            <!-- {{ "Turno :" + col.TypeDiscartN}}	 -->
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-row style="margin-top: 0.1em;">
                                                <v-col cols="12" lg="12" md="12" sm="12" >
                                                    <s-select-definition
                                                        label="Categoria"
                                                        :def="1326"
                                                        v-model="col.TypeFrozenCategory"
                                                        clearable
                                                    />
                                                    
                                                    <s-select-definition
                                                        label="Seleccion"
                                                        :def="1318"
                                                        v-model="col.TypeSelectionFrozen"
                                                        clearable
                                                    />
                                                </v-col>
                                                <v-col cols="12" lg="12" md="12" sm="12" >
                                                    <v-btn
                                                        outlined
                                                        small
                                                        elevation="3"
                                                        color="success"
                                                        style="width:100%"
                                                        @click="clickSend(col)"
                                                    >
                                                        Enviar
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-actions>																
                                    </v-card>	
                                </v-badge>
							</v-col>
						</v-row>
                    </v-card>

                    <v-card dense>
						<v-row style="margin: auto;">
							<v-col cols="12">
								<s-toolbar
									dark
									:color="'#BAB6B5'"
									class="mr-4 ml-2"
									style="display: flex"
									print
									
									@print="printQR()"
									
								></s-toolbar>
                                <!-- @view="view()" -->
                                <v-row justify="center">
                                    <v-col cols="4">
                                        <s-date label="Fecha Inicio"
                                            v-model="itemsSendDateBegin"
                                            @input="searchitemsSend($event)"
                                            >
                                        </s-date>
                                    </v-col>
                                    <v-col cols="4">
                                        <s-date label="Fecha Fin"
                                            v-model="itemsSendDateEnd"
                                            @input="searchitemsSend($event)"
                                            >
                                        </s-date>
                                    </v-col>
                                </v-row>
								<v-data-table
									:items="itemsSend"
									item-key="LlpID"
									dense
									:headers="headerSend"
									:items-per-page="15"
									disable-sort
									v-model="selectedItemsSend"
									@click:row="rowClickSend"
									 
								>
                                <!-- show-group-by
									:group-desc="true" -->
									<template v-slot:item.update="props">
										<v-tooltip top allow-overflow color="primary">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													x-small
													:color="'info'"
													fab
													style="margin: 6px 6px 6px 6px;"
													@click="ModelupdatePallet(props.item)"
													v-bind="attrs"
													v-on="on"
													>
													<v-icon color="white">
                                                        fa-light fa-pen
                                                    </v-icon>
												</v-btn>
											</template>
											<span>Editar</span>
										</v-tooltip>
									</template>

									<template v-slot:item.delete="props">
												<v-btn
													x-small
													:color="'error'"
													fab
													style="margin: 6px 6px 6px 6px;"
													@click="deleteParihuela(props.item)"
													>
													<v-icon style="font-size: 16px !important">fas fa-times</v-icon>
												</v-btn>
									</template>

									<template v-slot:item.PrfYuliano="row">
										<v-chip
											small
											dark
											outlined
											:color="'red'"
										>
											{{row.item.PrfYuliano}}  
										</v-chip>
									</template>	

                                    <template v-slot:item.LlpState="row">									
                                        <v-chip small :color="((row.item.LlpState == 1) ? 'warning' : 'success')">
                                            {{ ((row.item.LlpState == 1) ? 'Abastecido' : "En Maduracion") }}
                                        </v-chip>
                                    </template>

                                    <template v-slot:item.SecCreate="row">									
                                        {{ (new Date(row.item.SecCreate).getHours()) + ":"+
                                            ""+ (new Date(row.item.SecCreate).getMinutes())
                                        }}
                                    </template>

								</v-data-table>
							</v-col>
						</v-row>
					</v-card>



                </div>
            </v-row>
        </v-container>

        <v-dialog
			v-if="openDialog"
			v-model="openDialog"
			persistent
			transition="dialog-bottom-transition"
			width ="1200"
		>
        <!-- width ="1200" fullscreen -->
			<c-create-pallet-discardt
                @close="close($event)"
                @refresh="refresh"
                :TypeDiscartN ="TypeDiscartN"
                :PdlID ="PdlID"
                
            >
            </c-create-pallet-discardt>
		</v-dialog>

        <!-- PALLET DESCARTE VERDE PROCESO -->
        <v-dialog
			v-if="openDialogVP"
			v-model="openDialogVP"
			persistent
			transition="dialog-bottom-transition"
			width ="1200"
		>
        <!-- width ="1200" fullscreen -->
			<c-create-pallet-discardt-vp
                @closeVP="closeVP($event)"
                @refresh="refresh"
                :TypeDiscartN ="TypeDiscartN"
                :PdlID ="PdlID"
                
            >
            </c-create-pallet-discardt-vp>
		</v-dialog>

        <!-- Model para editar un pallet  -->
		<v-dialog
			v-if="openDialogEditPallet"
			width="600"
            height="200"
			v-model="openDialogEditPallet"
			persistent
			transition="dialog-bottom-transition">

			<s-toolbar
					label="Edición de pallet"
					dark
					save
					close
					color="primary"
					@save="editPallet(itemsEdit)"
					@close="closeEdit()">
			</s-toolbar>
			<v-card>
				<v-container>
					<v-row style="height:100px">

						<v-col cols="6">
							<s-select-definition
								:def="1318"
								v-model="itemsEdit.TypeSelectionFrozen"
								label="Tipo">
							</s-select-definition>
						</v-col>

						<v-col>
							<s-text 
								label="Peso Bruto"
								v-model="itemsEdit.LlpWeightOriginalGross" 
								decimal>
							</s-text>
						</v-col>

					</v-row>
				</v-container>
			</v-card>

		</v-dialog>

        <v-row justify="center" cols="12" style="display: none">
			<v-col cols="12" v-for="item in selected">
				<qr-code
					:text="
						item.LlpID +
							', Jabas: ' +
							item.LlpQuantityJaba +
							',' +
							item.TypeCultiveName +
							',' +
							item.VrtName
					"
					error-level="H"
					hidden
				>
				</qr-code
				><br />
			</v-col>
		</v-row>


        <!-- <v-dialog
			v-if="openViewQR"
			v-model="openViewQR"
			width="40%"
		>
			<prf-viewqr :selectedItemsSend="selectedItemsSend" @click="openViewQR = false"></prf-viewqr>
		</v-dialog> -->

	</div>
</template>
<script>
	//Service
    import _sFrzCtnPalletsDiscartLot from '@/services/FrozenProduction/FrzCtnPalletsDiscardLot';
    import _sLotProdSelPalletSendService from "../../../services/FrozenProduction/FrzLotProductionSelectionPalletSendService"
    import _sLotProdSelPalletService from "../../../services/FrozenProduction/FrzLotProductionSelectionPalletServiceList"
    import _sProcessSampling from '@/services/FrozenProduction/ProcessSamplingService';

    // components
    import CCreatePalletDiscardt from './FrzCtnPalletSamplingNew.vue';
    import CCreatePalletDiscardtVp from './FrzCtnPalletSamplingNewVP.vue';


	import qrCode from "vue-qr-generator";
	import _sGenParam from "@/services/General/ParameterService";

	export default {
		components: {  qrCode,CCreatePalletDiscardt,CCreatePalletDiscardtVp   },
		data: () => ({
			itemHead:{},
            itemsPallet: [],
            itemsSend: [],
            headerSend: [

                { text: "Borrar", value: "delete" },
				// { text: "Editar", value: "update" },
				{ text: "Correlativo", value: "LlpCorrelative" },
				{ text: "Id", value: "LlpID" },
				{ text: "Lote Producción", value: "PrfYuliano" },
				{ text: "Peso Bruto", value: "LlpWeightOriginalGross"},
				{ text: "Peso Neto", value: "LlpWeightOriginalNet" },
				{ text: "Tipo Selección", value: "TypeSelectionFrozenName" },
				{ text: "Categoría", value: "TypeFrozenCategoryName" },
				{ text: "Cant. Jabas", value: "LlpQuantityJaba" },
				{ text: "Tipo Cultivo", value: "TypeCropName" },
				{ text: "Cultivo", value: "TypeCultiveName" },
				{ text: "Variedad", value: "VrtName" },
				{ text: "Estado", value: "LlpState" },
				{ text: "Hora", value: "SecCreate" },
			],
            selectedItemsSend: [],
            openViewQR:false,
            openDialog: false,
            itemsSendDateBegin: "",
			itemsSendDateEnd: "",
            itemRcfid:[],
            textQR: "",
            qrcodestring: "",
            executeQR: false,
            selected: [],
            LlpID:0,
            itemsDetail:[],
            itemsDetailPallet:[],
            openDialogEditPallet: false,
            itemsEdit : {},
            openDialogVP: false,




		}),

		created() {
            this.initialize();
		},

		watch: {
		},

		methods: {
			CreatePallet(){
                if(this.itemHead.TypePallet == null){
					this.$fun.alert("No se ha selecionado tipo de Parihuela", "warning");
					return;
				}
				if(this.itemHead.PdlTypeDiscart == null){
					this.$fun.alert("No se ha selecionado tipo de Descarte", "warning");
					return;
				}

                console.log('crear pallet de descarte')
                this.$fun.alert("Esta seguro de crear Pallet?", "question").then((r) => {
                    if (r.value) {
                        let obj = {
                            TypePallet : this.itemHead.TypePallet,
                            PdlTypeDiscart : this.itemHead.PdlTypeDiscart,
                            UsrCreateID : this.$fun.getUserID(),
                            
                        }
                        _sFrzCtnPalletsDiscartLot
                        .savePallet(obj, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status == 200) {
                                this.$fun.alert("Registrado correctamente", "success");                
                                this.initialize();
                            }
                        });
                    }
                });
            },
            initialize(){
                _sProcessSampling.lst({},this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemRcfid = r.data;       
                        console.log('this.itemRcfid',this.itemRcfid);
                    }
                });

                _sFrzCtnPalletsDiscartLot.listPallet(this.$fun.getUserID())
                .then((r) => {
                        console.log("listar pallets para descarte", r.data);
                    if (r.status == 200) {
                        this.itemsPallet = r.data;
                        console.log();
                    }
                });
            },
            rowClickSend: function(item, row) {
				this.selected = [item];
				console.log("item cargados del item", item);
				console.log('items del selected', this.selected);
                if(this.selected.length > 0) {
                    _sFrzCtnPalletsDiscartLot.GetDetailQR({LlpID : this.selected[0].LlpID}, this.$fun.getUserID()).then(resp => {
                        if (resp.status == 200) {
                            this.itemsDetail = resp.data;
                            console.log("FFFF",this.itemsDetail);
                        }
                    });
                }
			},
            view(){
				
				this.openViewQR = true
			},
            removePallet(item){
                console.log('eliminar pallet',item);
                this.$fun.alert("¿Esta seguro de eliminar Pallet?", "question")
                .then((r)=> {
                    if (r.value) {
                        _sFrzCtnPalletsDiscartLot.removePallet(item, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status) {
                                this.$fun.alert("Se elimino correctamente", "success");
                                this.initialize();
                            }
                        });
                    }
                });
            },
            openModal(item)
            {
				this.TypeDiscartN = item.TypeDiscartN;
                this.PdlID = item.PdlID;
				console.log("items levantar modeal",item);
                if(item.PdlTypeDiscart == 2){
                    this.openDialog = true;
                }
                if(item.PdlTypeDiscart == 1){
                    this.openDialogVP = true;
                }
			},
            refresh(){
                this.initialize();
            },
            close(val) 
            {
				this.openDialog = false;
                this.initialize();
			},
            closeVP(val) 
            {
				this.openDialogVP = false;
                this.initialize();
			},
            clickSend(val) {
				console.log("antes de guardar ", val);
                if (val.TypeFrozenCategory == null) {
					this.$fun.alert("Seleccione una Categoria", "warning");
					return;
				}
                if (val.TypeSelectionFrozen == null) {
					this.$fun.alert("Seleccione tipo Seleccion", "warning");
					return;
				}
                this.getDataDetail(val); //  NO MOVER :)
                
				if (val.LlpQuantityJaba == 0) {
					this.$fun.alert("Pallet no se encuentra abastecido", "warning");
					return;
				}

				let obj = this.$fun.getUserInfo();
				val.PrsID =  obj.PrsID,
				val.SecStatus = 1;
				val.UsrCreateID = this.$fun.getUserID();

				console.log("Enviar pallet", val);

				this.pallet = val;
				val.LlpOriginStage = 1;
				this.$fun
                .alert("Esta de seguro de enviar Pallet ?", "question")
                .then(res => {
                    if (res.value) {
                        // ENVIAR CABECERA
                        this.itemsDetailPallet.forEach((element) => {
                            element.LlpID = null;
                            
                            element.LpsQuantityJaba = element.DldQuantityJaba;
                            element.TypeFrozenCategory = val.TypeFrozenCategory; 
                            element.TypeFrozenCategoryNew = val.TypeFrozenCategory;
                            element.LpsWeightPalletNet = element.DldWeightPalletNet;
                            element.LpsWeightPalletGross = element.DldWeightPalletGross;
                            element.LpsState = 1;
                            element.SecStatus = 1;
                            element.UsrCreateID = this.$fun.getUserID();
                            element.PrsID = this.$fun.getUserInfo().PrsID;
                            element.RcfID = element.RcfID;
                            element.TypeCultive = this.itemRcfid[0].TypeCultive;
                            element.TypeCrop = this.itemRcfid[0].TypeCrop;
                            element.VrtID = this.itemRcfid[0].VrtID;

                            // let data = {
                            //     LlpID : null,
                            //     LpsQuantityJaba : element.DldQuantityJaba,
                            //     TypeFrozenCategory : val.TypeFrozenCategory,
                            //     TypeFrozenCategoryNew : val.TypeFrozenCategory,
                            //     LpsWeightPalletNet : element.DldWeightPalletNet,
                            //     LpsWeightPalletGross : element.DldWeightPalletGross,
                            //     LpsState : 1,
                            //     SecStatus : 1,
                            //     UsrCreateID : this.$fun.getUserID(),
                            //     PrsID : this.$fun.getUserInfo().PrsID,
                            //     RcfID : element.RcfID,
                            //     TypeCultive : this.itemRcfid[0].TypeCultive,
                            //     TypeCrop : this.itemRcfid[0].TypeCrop,
                            //     VrtID : this.itemRcfid[0].VrtID
                            // }
                            // console.log('suma',data);
                            
                        });   
                        // return
                        _sLotProdSelPalletService
                        .saveDiscart(this.itemsDetailPallet, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status == 200) {
                                let suma = 0 ;
                                this.itemsDetailPallet.forEach((x) => {
                                    suma += (x.DldWeightPalletNet); 
                                });   
                                console.log('suma',suma);
                            //    return
                                let valSend ={}
                                    valSend.LlpWeight = suma;
                                    valSend.LlpQuantityJaba = val.SumDldQuantityJaba; //this.itemsDetailPallet[0].SumDldQuantityJaba;
                                    valSend.LlpWeightOriginalNet =  suma;
                                    valSend.LlpWeightOriginalGross = this.itemsDetailPallet[0].DldWeightPalletGross;
                                    valSend.IsConditioned = 1;

                                    valSend.TypeSelectionFrozen = val.TypeSelectionFrozen;
                                    valSend.TypeFrozenCategory =  val.TypeFrozenCategory;
                                    valSend.TypeDestiny = 2 ;
                                    valSend.PrsID = this.$fun.getUserInfo().PrsID;
                                    valSend.TypePalletMate  = val.TypePallet;
                                    valSend.LlpOriginStage = 1;
                                    valSend.TypeCultive = this.itemRcfid[0].TypeCultive;
                                    valSend.TypeCrop = this.itemRcfid[0].TypeCrop;
                                    valSend.VrtID = this.itemRcfid[0].VrtID;
                                    valSend.SecStatus = 1;
                                    valSend.UsrCreateID = this.$fun.getUserID();
                                
                                    console.log('send',valSend)
                                    // return


                                _sFrzCtnPalletsDiscartLot
                                .sendConditioned(valSend, this.$fun.getUserID())
                                .then(r => {
                                    if (r.status == 200) {
                                            // modificar estado en tabla frzCtnpalletsDiscartLot
                                            let obj = {
                                                PdlID : val.PdlID, //this.itemsDetailPallet[0].PdlID, // VERIFICAR
                                                UsrUpdateID : this.$fun.getUserID(),
                                            }
                                            console.log('objjj',obj)
                                            _sFrzCtnPalletsDiscartLot
                                            .UpdatePalletDiscart(obj, this.$fun.getUserID())
                                            .then((r) => {
                                                if (r.status == 200) {
                                                    this.initialize();
                                                    this.getPalletSend();
                                                }
                                            });
                                            
                                            this.$fun.alert(
                                                "Pallet creado con exito",
                                                "success",
                                            );
                                            
                                            this.executeQR = true
                                        
                                            // this.getPalletSend();
                                    }
                                });
                            }
                        })

                        // FIN DE ENVIAR CABECERA

                            
                       
                    }
                });
            },
            getPalletSend()
            {
                if(this.itemsSendDateBegin == "" || this.itemsSendDateEnd == ""){
					return;
				}
                ////
                _sFrzCtnPalletsDiscartLot
				.getlotproductionselectionpalletsend(
                { 
                    DateBegin : this.itemsSendDateBegin,
					DateEnd : this.itemsSendDateEnd
                }
                    , this.$fun.getUserID())
                    .then(r => {
                    this.itemsSend = r.data;

                    console.log("item cargados", this.itemsSend);

                    if(this.executeQR){
                        if(this.itemsSend.length > 0){
                            let ob = this.itemsSend[0];
                            this.selected = [ob];
                            this.rowClickSend(ob, ob);

                            if(this.selected.length > 0) {

                                _sFrzCtnPalletsDiscartLot.GetDetailQR({LlpID : this.selected[0].LlpID}, this.$fun.getUserID()).then(resp => {
                                    if (resp.status == 200) {
                                        this.itemsDetail = resp.data;
                                        this.printQR();
                                        console.log("item seleccionado", ob);
                                        this.executeQR = false
                                        
                                    }
                                });
                            } 
                        }
                    }
				});
                
			},
            searchitemsSend(date){
				console.log("date", date);
				this.getPalletSend();
			},
            printQR() {
				var imgAll = document.querySelector("img");
				console.log('imgAll ', imgAll);
				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style  media='print'> body{font-family: arial} table, th, td {border: 1px solid black;border-collapse: collapse;padding:2px;font-size:9pt;}" + 
						" @media print  { @page { margin-left: 0.5cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
				);

				//samir LlpID
				 
				/* imgAll.forEach(element => { */
					pwa.document.write(
						"<div class='col col-4' style='margin: 20px 20px 20px 20px; position:absolute;'><img alt='Scan me!' style='display: block;'  src='" +
							imgAll.currentSrc +
							"' />",
					);
					pwa.document.write("<b style='font-size: 50px; z-index: 2;transform: rotateZ(0deg);margin-top: 40px;position: absolute;width: 133px;border-radius: 20px;'>" +
                        this.selected[0].TypeSelectionFrozenName
                        +"</b>"); 

					pwa.document.write("</div><div style='margin: 50px 10px 0px 410px;'><table border='1'>");
					pwa.document.write(
						"<tr><td ><b>Sunshine</b></td>" + 
								"<td align='center'><b> " + this.selected[0].TypeCultiveName + "-" + this.selected[0].VrtName +
								" <br> "   + this.selected[0].TypeCropName +
								" </b></td></tr>"+
								 "<tr><td>AP-FR-213</td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VERSION:03" +
								"<br>F. creación:20/09/2021" +
								"<br>F. última revisión:20/10/2022</td></tr>" +														 
							"<tr><td>Peso Neto</td><td>" + this.selected[0].LlpWeightOriginalNet + " KG.</td></tr>"+
							"<tr><td>Tipo Selección</td><td>" +  	
                                ((this.selected[0].TypeSelectionFrozenName === null) ? 'DESCARTE ' : this.selected[0].TypeSelectionFrozenName) +
							"</td></tr><tr><td>Tamaño</td><td>"+ this.selected[0].TypeFrozenCategoryName + " </td></tr>" + 			
							"<tr><td>ID pallet sunexpert</td><td>"+ this.selected[0].LlpID + "</td></tr>" +			
							"<tr><td>N° Pallet</td><td>" + this.selected[0].LlpCorrelative + "</td></tr>" +
							"<tr><td>Turno</td><td>RECEPCION|MADURACION</td></tr>" +
							"<tr><td>%LMF</td><td></td></tr>" +
							"<tr><td>F.Proceso(Tunel)</td><td></td></tr>" +
							"<tr><td>Peso a Proceso</td><td></td></tr>" +
							"<tr><td>Tipo de Proceso</td><td></td></tr>" +
							"<tr><td>Estado de Madurez</td><td></td></tr>"+
							"<tr><td>Hora</td><td>"+ (new Date(this.selected[0].SecCreate).getHours()) + ":"+
								""+ (new Date(this.selected[0].SecCreate).getMinutes()) + "</td></tr>"
					);
					pwa.document.write("</table></div>");
                    // console.log('itemsdetail',this.itemsDetail[0]);
					this.itemsDetail.forEach(detail => {
                            // pwa.document.write('itemsDetail',this.itemsDetail);
							pwa.document.write("<div style='margin: 30px 0px 0px 410px;'><table border='1'>");

							pwa.document.write(
								"<tr><td>Alias/Juliano: </td><td>" + detail.PrfYuliano+ "</td></tr>" +
								"<tr><td>Jabas: </td><td>" + detail.LlpQuantityJaba +"</td></tr>"
							);

							pwa.document.write(
								// "<tr><td>Productor: </td><td>" + detail.PrdCardName +"</td></tr>" +
								/* "<tr><td>Fundo: </td><td>" + detail.FagName + " || "+ detail.FltName + "</td></tr>" + */
								"<tr><td>Procedencia: </td><td>" + detail.ZonName + " - " + detail.TypeZonLocationName + "</td></tr>"
							);

							pwa.document.write(
								// "<tr><td>Certificaciones: </td><td>" + detail.TcDescription +"</td></tr>" +
								"<tr><td>Fecha Planta</td><td>" + detail.RcfDateReception + "</td></tr>" +
								"<tr><td>Fecha Calibrado</td><td>" + detail.RcfDateCalibrated + "</td></tr>" 
							);
						 
							if (this.selected[0].TypeCultiveName == "PALTO") {
								pwa.document.write(								 
									"<tr><td>% De Materia Seca</td><td>" + detail.DryMaterial + "</td></tr>"  
								);
							}

							pwa.document.write("</table></div>");
						});

			
				pwa.document.write("</div></body></html>");
				pwa.document.close();
			},
            getDataDetail(item){
                _sFrzCtnPalletsDiscartLot.listDetailPallets(item,this.$fun.getUserID())
                .then((r) => {
                       
                    if (r.status == 200) {
                        this.itemsDetailPallet = r.data;
                        console.log('datalle de pallet',this.itemsDetailPallet);
                    }
                });
            },
            deleteParihuela(val){

                console.log("ELIMINAR", val);
                val.SecStatus = 0;
                val.LlpOriginStage = 1;
                val.UsrCreateID = this.$fun.getUserID();

                this.$fun
                .alert("Esta de seguro de eliminar Pallet ?", "question")
                .then(res => {
                    if (res.value) {
                        
                        _sLotProdSelPalletSendService
                            .send(val, this.$fun.getUserID())
                            .then(r => {
                                if (r.status == 200) {
                                    this.$fun.alert(
                                        "Pallet elimiado con exito",
                                        "success",
                                    );
                                    this.initialize();
                                    this.getPalletSend();
                                }
                            });
                    }
                });
            },
            ModelupdatePallet(item) {
				console.log('datos pasados para editar', item);
				this.itemsEdit = {};
				this.itemsEdit = item;
				this.openDialogEditPallet = true;

			},
            editPallet(item){

                item.SecStatus = 1;
                item.LlpOriginStage = 1;
                item.UsrCreateID = this.$fun.getUserID();

                console.log('datos actualizados', item)

                this.$fun.alert("Esta seguro de actualizar el pallet? ", "question" )
                .then(res => {
                    if(res.value) {
                        _sLotProdSelPalletSendService
                        .send(item, this.$fun.getUserID())
                        .then(r => {
                            if (r.status == 200) {
                                this.$fun.alert(
                                    "Pallet actualizado correctamente",
                                    "success",
                                );
                                this.initialize();
                                this.getPalletSend();
                                this.openDialogEditPallet=false
                            }
                        });
                    }
                })
            },
            closeEdit() {
				this.itemsEdit = {};
				this.openDialogEditPallet = false;
			},













		},

		mounted() {
        }
	};
</script>

<template>
	<v-card class="m-0 p-0">
		<v-col cols="12" lg="12" md="12" style="display: contents;" >
			<template style="margin-top: 10px;">
				<s-crud
					:filter="filter"
					:config="configLot"
					add
					close
					addNoFunction
					no-full
					:title="label"
					@rowSelected="rowSelected($event)"
					@add="savePalletCork($event)"
					@close="close()"
					ref="gridCreatePallet"
					height="200px"
				>
                    <template v-slot:filter>
                        <v-container>
                            <v-row>
                                <v-col cols="4" lg="3" md="4" sm="12">
                                    <s-select-definition
                                        label="Cultivo" 
                                        :def="1173"
                                        v-model="filter.TypeCultive"
                                        @input="getLines($event)"
                                        clearable
                                    ></s-select-definition>
                                </v-col>
                                <v-col cols="4" lg="3" md="4" sm="12">
                                    <s-select
                                        :items="itemsLineas"
                                        item-value="ClpID"
                                        item-text="LncDescription"
                                        label="Lineas"
                                        v-model="filter.ClpID"
                                        ref="txtClpID"
                                        @input="getTablesLine($event)"
                                        clearable
                                    
                                    >
                                    </s-select>
                                </v-col>
                                <v-col v-if="filter.TypeCultive == 3" cols="4" lg="3" md="4" sm="12">
                                    <s-select
                                        :items="itemTableDoll"
                                        item-value="DtbID"
                                        item-text="CttName"
                                        label="Estacion de Trabajo"
                                        v-model="DtbID"
                                        ref="txtDtbID"
                                        clearable
                                        @input="getNumberLote($event)"
                                    >
                                    </s-select>
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12">
                                    <s-select
                                        :items="itemRcfid"
                                        item-value="DtbID" 
                                        item-text="RcfDate"
                                        label="Numero de Lote"
                                        v-model="filter.newRcfID"
                                        return-object
                                        ref="txtRcfID"
                                        clearable
                                    
                                    >
                                   
                                    </s-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                        <br>

                        <template v-slot:DldQuantityJaba="{ row }">
                            <s-text
                                number
                                v-model="row.DldQuantityJaba"
                                @input="inputJaba(row.DldQuantityJaba)"
                                :min="0">
                            </s-text>
                        </template>

                        <template v-slot:Cork="{ row }">
                            <v-chip small color="warning">
                                {{
                                    row.Cork
                                }}
                            </v-chip>
                        </template>

                        <template v-slot:JabasResiduaryCK="{ row }">
                            <v-chip small color="error">
                                {{
                                    row.JabasResiduaryCK
                                }}
                            </v-chip>
                        </template>

                        <template v-slot:corcho="{ row }">
                            <v-chip small color="info">
                                {{
                                     TypeDiscartN
                                }}
                            </v-chip>
                        </template>
                        
                        <template v-slot:KilosToReduce="{ row }">
                            <s-text
                            
                            decimal
                            v-model="row.KilosToReduce"
                            @input="inputWeight(row.KilosToReduce)">
                            </s-text>
                        </template>
				</s-crud>
			</template>
		</v-col>

		<v-col cols="12" lg="12" md="12">
			<template>
				<s-toolbar
					label="Detalle de Pallet"
					dark
					:color="'#BAB6B5'"
					class="mb-6"
				></s-toolbar>
			

				<v-data-table
					item-key="DldID"
					dense
					:items="itemsDiscartDetailPallet"
					:headers="headerssDiscartDetailPallet"
					v-model="selectedDiscartDetailPallet"
					@click:row="rowClick"
					style="margin-top: -20px;"
					disable-sort>


                    <template v-slot:item.TypeDiscart="{ item }">
                        <v-chip small color="info">
                            {{
                                TypeDiscartN
                            }}
                        </v-chip>
                    </template>

					<template v-slot:item.Actions="{ item }">
						<v-btn
							@click="clickAnulate(item)"
							x-small
							color="error"
							elevation="0"
							block
							outlined>
							<v-icon style="font-size: 16px !important">
								fas fa-times
							</v-icon>
						</v-btn>
					</template>

				

				</v-data-table>
				<p>
					Cantidad de Jabas: <b>{{ this.sumJaba() }}</b>
				</p>
			</template>
		</v-col>
	</v-card>
</template>

<script>
	// import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";
	// import _sLotProdSelPalletService from "../../../services/FrozenProduction/FrzLotProductionSelectionPalletService";
    import _sFrzCtnPalletsDiscartLot from '@/services/FrozenProduction/FrzCtnPalletsDiscardLot';
    import _sGenParamService from "../../../services/General/ParameterService";
    import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"


    import _sTurnService from "@/services/FreshProduction/PrfTurnService";


    import _sProcessSampling from '@/services/FrozenProduction/ProcessSamplingService';

	export default {
		props: {
			TypeDiscartN: {
				type: String,
				default: "",
			},
            PdlID:{
                type: Number,
                default :0,
            },
            DldQuantityJaba :{
                type:Number,
                default: 0,
            },
		},
		components: {},
		data: () => ({
            //
            itemRcfid:[],
            //

			// reducedAmount:0,
			headerssDiscartDetailPallet: [
				{ text: "Anular", value: "Actions", width: 20 },
				{ text: "Nro. Lote Prod.", value: "RcfID", width: 20 },
				{ text: "Tipo descarte", value: "TypeDiscart", width: 20 },
				{ text: "N° Jabas", value: "DldQuantityJaba", width: 20 },
				{ text: "Peso Neto pallet", value : "DldWeightPalletNet", width: 60},
				{ text : "Peso Bruto Pallet", value : "DldWeightPalletGross", width: 60}, 
			],
            

			filter: { 
				RcfID:0,
			},
			 configLot: {
				service: _sFrzCtnPalletsDiscartLot,
				model: {
					RcfID: "ID",
                    DldQuantityJaba : "",
                    Cork : "",
                    corcho :"",
                    TypeCultiveName:"",
                    KilosToReduce:"",
                    JabasResiduaryCK : "",
 
				},
				headers: [
                    { text: "N° Jabas", value: "DldQuantityJaba", width: 20 },
                    { text: "Total jabas Corcho", value: "Cork", width: 100 },
                    { text:"Peso Kilos (KG)", value:"KilosToReduce", width: 100},
                    { text: "Lote Producción", value: "RcfID",width:100},
                    { text: "Jabas Restantes", value: "JabasResiduaryCK",width:100},
                    { text: "Cultivo", value: "TypeCultiveName",width:100},
                    { text: "Tipo Descarte", value: "corcho", width: 100 },					
				],
			},
            DldQuantityJabaCK : 0,
            itemsDiscartDetailPallet:[],
            selectedDiscartDetailPallet:[],
            TypeDiscart :"",
            AverageWeightPallet:null,
            TypeCultiveName:"",
            jabaMax: 0,
            Turn: 0,
            itemsLineas: [],
            TypeCultive: 0,
            ClpID: 0,
            itemTableDoll: [],
            DtbID: 0,
            newRcfID:{},
        
		}),
       

		created() {

            _sGenParamService
            .search(
                { PrmName: "FrozenCalibradoArmadoParihJabas" },
                this.$fun.getUserID(),
            )
            .then(resp => {
                if (resp.status == 200) {
                    this.jabaMax = resp.data.PrmValue;
                }
            });

			this.label = 'Armado de Pallet - ' + this.TypeDiscartN;
			this.initialize();
            this.getDataDetail()
            this.TurnActive();

		},

		methods: {
            TurnActive(){
                let TypeArea = 4
                _sTurnService.turnActive({TypeArea : TypeArea},this.$fun.getUserID()).then(r =>{
                        if(r.status == 200)
                        {
                            this.Turn = r.data[0].TrnID;           
                            console.log('turno', this.Turn);
                                  
                        }
                    });      
            },
            getLines(item){
                let processInitial = {
                        TypeCultive: item,
                        TrnID: this.Turn

                    }
                    
                    _sFrzCtnLinesProcessService.listLineas(processInitial, this.$fun.getUserID()).then(r => {
                        if(r.status == 200){
                            this.itemsLineas = r.data;     
                        }
                    })
            },
            getTablesLine(item){                
                console.log("getTablesLine-->", item);
                if(item != 0)
                {                
                    _sFrzCtnLinesProcessService.listTablesByLines({ClpID: item},this.$fun.getUserID()).then(r => {
                        if(r.status == 200){
                            this.itemsTablesLines = r.data
                            
                                this.itemTableDoll = this.itemsTablesLines.filter(x => x.DtbID > 0);           
                                this.initialize();                     
                                
                        }
                    })
                }
            },
			inputWeight(val){
				if(val !== undefined){
					this.LpsWeightPallet = val;
				}
			},
			
            sumJaba() {
                var total = 0;
                this.itemsDiscartDetailPallet.forEach(element => {
                    total += element.DldQuantityJaba;
                });
                return total;
            },
			close() {
				this.$emit("close");
                this.$emit("refresh");
			},
			rowSelected(val) {
				if (val.length > 0) {
					this.items = val[0];
				}
			},

			rowClick: function(item, row) {
				this.selectedDiscartDetailPallet = [item];
			},

			inputJaba(val) {
				if (val !== undefined) {
					this.DldQuantityJabaCK = val;
				}
			},
			initialize()
            {
                let filter = {
      
                    ClpID : this.ClpID,
                    DtbID : this.DtbID

                }
                console.log ('filtra',filter)
                // return
                _sProcessSampling.lst(filter, this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemRcfid = r.data;                                
                    }
                });            
            },
            isValidated(quantity) {
                var isValidated = true;

                let isDifference = 0;
                isDifference = this.jabaMax - this.sumJaba();

                if (quantity > isDifference) {
					this.$fun.alert(
						"Esta superando la cantidad maximo de llenado de Pallet ==> Max = " +
							this.jabaMax,
					);
					isValidated = false;
					return;
				}

                if (this.DldQuantityJabaCK <= 0) {
                    this.$fun.alert("La Cantidad debe ser mayor a 0", "warning");
                    isValidated = false;
                    return;
                }
                if (this.KilosToReduce <= 0) {
                    this.$fun.alert("La Cantidad debe ser mayor a 0", "warning");
                    isValidated = false;
                    return;
                }

                return isValidated;
            },
            
            savePalletCork(val){
                console.log('Guardar Pallet Corcho',val);
                console.log('cantidad jabas ', val.DldQuantityJaba);

                if (!this.isValidated(this.KilosToReduce)) {
					return;
				}

                if (!this.isValidated(this.DldQuantityJabaCK)) {
					return;
				}

                let obj = this.$fun.getUserInfo();
                val.LlpID = null;
                val.DldQuantityJaba = this.DldQuantityJabaCK;

                if (val == null) {
					this.$fun.alert("No existen item", "warning");
					return;
				}

                let exist = false;
				this.itemsDiscartDetailPallet.forEach(element => {
					
					if(element.RcfID == val.RcfID){
						exist = true
						this.$fun.alert("Error, El Lote de Producción ya fue agregado, elimine anterior registro y vuelva agregarlo", "warning")//🚀
						return
					}
				});
                if(exist == false){
                    this.$fun.alert("Esta seguro de guardar?", "question").then((r) => {
                        if (r.value) {
                            let obj = {
                                DldQuantityJaba : this.DldQuantityJabaCK,
                                DldWeightPalletGross : this.LpsWeightPallet,
                                TypeCultive : val.TypeCultive,
                                RcfID : val.RcfID,
                                PcsID : val.PcsID,
                                UsrCreateID : this.$fun.getUserID(),
                            }
                            _sFrzCtnPalletsDiscartLot
                            .saveDiscartCork(obj, this.$fun.getUserID())
                            .then((r) => {
                                if (r.status == 200) {
                                    this.$fun.alert("Registrado correctamente", "success");           
                                    this.$refs.gridCreatePallet.refresh();    
                                    this.getDataDetail(this.PdlID) 
                                }
                            });
                        }
                    });
                }
            },
            getDataDetail(val){
                
                _sFrzCtnPalletsDiscartLot.listDetailPallets({PdlID : this.PdlID},this.$fun.getUserID())
                .then((r) => {
                       
                    if (r.status == 200) {
                        this.itemsDiscartDetailPallet = r.data;
                        console.log('datalle de pallet',this.itemsDiscartDetailPallet);
                    }
                });
            },

            clickAnulate(val) {
            console.log('eliminar registro',val)
            val.UsrCreateID = this.$fun.getUserID();

                this.$fun
                .alert("Seguro de anular registro ?", "question")
                .then(res => {
                    if (res.value) {
                        let item = {
                            DldID : val.DldID,
                            UsrUpdateID : this.$fun.getUserID(),

                        }
                        // val.SecStatus = 0;
                        // val.DldID ;
                        _sFrzCtnPalletsDiscartLot
                        .AnulateDiscart(item, this.$fun.getUserID())
                        .then(r => {
                            if (r.status == 200) {
                                this.$fun.alert("Registro anulado", "success");
                                this.getDataDetail(this.PdlID)
                                // this.TablesLinesLst(this.line.ClpID);
                                this.$refs.gridCreatePallet.refresh();
                            }
                        });
                    }
                });
            },
            getNumberLote(item)
            {                 
                if(item != 0)
                {
                    this.initialize();
                }
            }
           


		},

       


			

			
		

		mounted(){
			
			 
		}
	};
</script>

<style></style>

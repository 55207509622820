import Service from '../Service';

const resource = "processsampling/";


export default{

    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },

    lst(obj, requestID){
        return Service.post(resource + "lst", obj,{
            params: {requestID: requestID},
        });
    },
    listdetail(prd, requestID) {
        return Service.post(resource + "listdetail", prd, {
            params: { requestID: requestID },
        });
    },
};